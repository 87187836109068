// @ts-nocheck

import { RootnetItem } from '@shared/models/rootnet';

function processQuestions(response, workflowTypeId, workflowTypeFieldMachine) {
  const customerSuccess = response.list.find(
    (value: RootnetItem) => parseInt(value.id, 10) === workflowTypeId
  );
  const { fields } = customerSuccess;
  const field = fields.find((f) => f.machine_name === workflowTypeFieldMachine);
  const fieldConfig = JSON.parse(field.field_config);

  const quiz = fieldConfig.presetRows.map((value: object, indexVal: number) => ({
    ...value,
    uuid: `${indexVal}-quiz`,
    question: {
      ask: value['1'],
      answers: fieldConfig.subFields.map((answer: { field_name: string, configuration: string, required: boolean }, index: number) => ({
        uuid: `${indexVal}-${index}-answer`,
        fieldName: answer.field_name,
        configuration: answer.configuration,
        required: answer.required,
        value: value[index]
      }))
    }
  }));

  return { field, quiz };
}

function processEditSuccessForm(response, workflowTypeFieldMachine) {
  let quiz = [];
  const { metas } = response.workflow;
  const metaInfo = metas.find((m) => m.workflow_type_field_machine === workflowTypeFieldMachine);

  if (metaInfo) {
    const values = JSON.parse(metaInfo.value);
    const configFields = JSON.parse(metaInfo.workflow_type_field_config).subFields;
    quiz = values.map((value: object, indexVal: number) => ({
      ...value,
      uuid: `${indexVal}-quiz`,
      question: {
        ask: value['1'],
        answers: configFields.map((answer: { field_name: string, configuration: string, required: boolean }, index: number) => ({
          uuid: `${indexVal}-${index}-answer`,
          fieldName: answer.field_name,
          configuration: answer.configuration,
          required: answer.required,
          value: value[index]
        }))
      }
    }));
  }

  return quiz;
}

export {
  processQuestions,
  processEditSuccessForm
};
