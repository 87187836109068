const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const isDevelopment = activeEnv === 'development';

// id workflow type 'Customer Success'
let workflowTypeId = 81;
// id Title 'Customer Success' field required to create a workflow
let workflowTypeFieldId = 1994;

let workflowTypeFieldMachine = 'NPS918';
let workflowTypeFieldMachineQuestions = 'Customer Success1405';
let workflowEmailTypeFieldId = 2249;
if (isDevelopment) {
  workflowTypeId = 65;
  workflowTypeFieldMachine = 'Customer Success1359';
  workflowTypeFieldId = 1984;
  workflowEmailTypeFieldId = 425;
}

exports.workflowTypeId = workflowTypeId;
exports.workflowTypeFieldMachine = workflowTypeFieldMachine;
exports.workflowTypeFieldMachineQuestions = workflowTypeFieldMachineQuestions;
exports.workflowTypeFieldId = workflowTypeFieldId;
exports.workflowEmailTypeFieldId = workflowEmailTypeFieldId;
