// @ts-nocheck

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Alert, Col, Form, Row
} from 'react-bootstrap';
import moment from 'moment';

import decode from '@core/services/jwt-service';
import Footer from '@core/layout/footer/footer';
import {
  fetchGetWorkflowsTypesAllFields,
  fetchPathWorkflowsToApplyField,
  fetchPostWorkflowsToApplyField,
  fetchWorkflowsToApplyField
} from '@core/services/rootnet-service';

import { processEditSuccessForm, processQuestions } from '@modules/customer_success_forms/utils';
import HeaderCustomerSuccess from '@modules/customer_success/header-customer-success';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import './styles.scss';

const {
  workflowTypeId,
  workflowTypeFieldMachine,
  workflowTypeFieldMachineQuestions,
  workflowTypeFieldId,
  workflowEmailTypeFieldId
} = require('@modules/customer_success_forms/config');
const { customerSuccessForm: crumbs } = require('@config/breadcrumbs');

export default function CustomerSuccessFormPage() {
  const { t } = useTranslation();

  const initialState = {
    id: '',
    title: '',
    created_at: '',
    metas: [],
  };

  const [formState, setFormState] = React.useState({
    workflow: { ...initialState },
    questions: [],
    newQuestions: [],
    workflowTypeFieldId: '',
    newQuestionsworkflowTypeFieldId: '',
    isLoading: false,
    variant: '',
    message: '',
    token: '',
    expired: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const fetchWorkflows = async (token = formState.token) => {
    setFormState({ ...formState, variant: '' });
    const decoded = await decode(token) || {};
    const now = moment(decoded.expiresAt);
    const end = moment(new Date());
    const duration = moment.duration(now.diff(end));
    const days = duration.asDays();

    if ((Math.floor(days) + 1) <= 0) {
      setFormState({ ...formState, expired: true });
      return;
    }

    try {
      let quiz = [];
      let newQuiz = [];
      setFormState({ ...formState, isLoading: true });
      const response = await fetchWorkflowsToApplyField(decoded.entityId);
      quiz = processEditSuccessForm(response, workflowTypeFieldMachine);
      newQuiz = processEditSuccessForm(response, workflowTypeFieldMachineQuestions);

      setFormState((prevState) => ({
        ...prevState,
        workflow: { ...response.workflow },
        questions: quiz,
        newQuestions: newQuiz,
        variant: ''
      }));
    } catch (e) {
      setFormState({ ...formState, variant: 'danger', message: t('Customer Success Wrong') });
    } finally {
      setFormState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const onSubmitForm = async () => {
    setFormState({ ...formState, variant: '', isLoading: true });
    const dateCreate = moment(new Date());
    const parsedData = {
      workflow_type_id: workflowTypeId,
      title: formState.workflow?.title,
      start: dateCreate.format('YYYY-MM-DD'),
      workflow_metas: [
        {
          workflow_type_field_id: formState.workflowTypeFieldId,
          value: JSON.stringify(formState.questions)
        },
        {
          workflow_type_field_id: formState.newQuestionsworkflowTypeFieldId,
          value: JSON.stringify(formState.newQuestions)
        },
        {
          value: formState.workflow?.email,
          workflow_type_field_id: workflowEmailTypeFieldId
        }
      ],
      workflow_base_metas: [
        {
          value: formState.workflow?.title,
          workflow_type_field_id: workflowTypeFieldId
        },
        {
          value: formState.workflow?.email,
          workflow_type_field_id: workflowEmailTypeFieldId
        }
      ],
      description: '<p><span style="color: rgb(0, 0, 0);"></span></p>',
      created_at: dateCreate.format('YYYY-MM-DD')
    };

    let execute;
    if (formState.workflow.id) {
      const { metas } = formState.workflow;
      const meta = metas.find((m) => m.workflow_type_field_machine === workflowTypeFieldMachine);
      const metaQuestions = metas.find(
        (m) => m.workflow_type_field_machine === workflowTypeFieldMachineQuestions
      );
      const payload = {
        workflow_id: formState.workflow.id,
        workflow_metas: [
          {
            workflow_type_field_id: meta?.workflow_type_field_id,
            value: JSON.stringify(formState.questions)
          },
          {
            workflow_type_field_id: metaQuestions?.workflow_type_field_id,
            value: JSON.stringify(formState.newQuestions)
          },
          {
            value: formState.workflow?.email,
            workflow_type_field_id: workflowEmailTypeFieldId
          }
        ]
      };
      execute = fetchPathWorkflowsToApplyField(formState.workflow.id, JSON.stringify(payload));
    } else {
      execute = fetchPostWorkflowsToApplyField(parsedData);
    }

    try {
      await execute;
      setFormState({ ...formState, variant: 'success', message: t(formState.workflow.id ? 'Customer Success Updated' : 'Customer Success Created') });
    } catch (_) {
      setFormState({ ...formState, variant: 'danger', message: t(formState.workflow.id ? 'Customer Failed Update' : 'Customer Failed Create') });
    } finally {
      setFormState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const setValueQuestion = (questionIndex: number, answerId: number, value: string|boolean) => {
    formState.questions[questionIndex][`${answerId + 2}`] = value;
    formState.questions[questionIndex].answer = value;
    formState.questions[questionIndex].question.answers[answerId + 2].value = value;
    setFormState({
      ...formState,
      questions: [...formState.questions]
    });
  };

  const setValueNewQuestion = (
    questionIndex: number,
    answerId: number,
    value: string | boolean
  ) => {
    formState.newQuestions[questionIndex][`${answerId + 2}`] = value;
    formState.newQuestions[questionIndex].answer = value;
    formState.newQuestions[questionIndex].question.answers[answerId + 2].value
      = value;
    setFormState({
      ...formState,
      newQuestions: [...formState.newQuestions]
    });
  };

  const fetchQuestionsWorkflow = async () => {
    try {
      setFormState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchGetWorkflowsTypesAllFields();
      const { field, quiz } = processQuestions(response, workflowTypeId, workflowTypeFieldMachine);
      setFormState((prevState) => ({
        ...prevState,
        questions: quiz,
        workflowTypeFieldId: field.id,
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      setFormState((prevState) => ({ ...prevState, variant: 'danger', message: t('Customer Success Wrong') }));
    } finally {
      setFormState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const fetchNewQuestionsWorkflow = async () => {
    try {
      setFormState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchGetWorkflowsTypesAllFields();
      const { field, quiz } = processQuestions(
        response,
        workflowTypeId,
        workflowTypeFieldMachineQuestions
      );
      setFormState((prevState) => ({
        ...prevState,
        newQuestions: quiz,
        newQuestionsworkflowTypeFieldId: field.id
      }));
    } catch (e) {
      setFormState((prevState) => ({
        ...prevState,
        variant: 'danger',
        message: t('Customer Success Wrong')
      }));
    } finally {
      setFormState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const errorField = (fieldName: string) => (errors && errors[fieldName] ? 'border-danger' : '');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('workflow');

    if (token) {
      fetchWorkflows(token);
    } else {
      fetchQuestionsWorkflow();
      fetchNewQuestionsWorkflow();
    }
  }, []);

  const renderQuestionsStep = (answer, indexQuiz: number, index: number) => {
    if (answer.configuration.type_info.type === 'checkbox') {
      return (
        <div
          key={answer.uuid}
          className="form-check form-check-inline mx-4 my-2"
        >
          <input
            className="form-check-input"
            type="checkbox"
            checked={answer.value}
            id={answer.uuid}
            onChange={(event) => {
              setValueQuestion(indexQuiz, index, event.target.checked);
            }}
          />
          <label className="ml-2 form-check-label" htmlFor={answer.uuid}>
            {answer.fieldName}
          </label>
        </div>
      );
    }
    return (
      <div className="mx-4 my-2" key={answer.uuid}>
        <input
          id={answer.uuid}
          type="text"
          className="form-control w-25"
          value={answer.value}
          onChange={(event) => {
            setValueQuestion(indexQuiz, index, event.target.value);
          }}
        />
      </div>
    );
  };

  const renderNewQuestionsStep = (answer, indexQuiz: number, index: number) => {
    if (answer.configuration.type_info.type === 'checkbox') {
      return (
        <div
          key={answer.uuid}
          className="form-check form-check-inline mx-4 my-2"
        >
          <input
            className="form-check-input"
            type="checkbox"
            checked={answer.value}
            id={answer.uuid}
            onChange={(event) => {
              setValueNewQuestion(indexQuiz, index, event.target.value);
            }}
          />
        </div>
      );
    }
    return (
      <div className="mx-4 my-2" key={answer.uuid}>
        <textarea
          id={answer.uuid}
          type="text"
          className="form-control"
          value={answer.value}
          onChange={(event) => {
            setValueNewQuestion(indexQuiz, index, event.target.value);
          }}
        />
      </div>
    );
  };

  const isFormComplete = () => formState?.workflow?.email
      && formState?.workflow?.title
      && formState.questions.every((question) => question.answer)
      && formState.newQuestions.every((question) => question.answer);

  return (
    <>
      <HeaderCustomerSuccess />
      <Breadcrumb
        crumbs={crumbs.concat({ label: t('Apply form'), link: '#' })}
      />
      {formState.workflow.id && (
        <div className="container rounded-lg bg-light p-md-4 mt-4 shadow-sm border">
          <h5>{formState.workflow.title}</h5>
          <h6>
            {t('Customer Success Creation date')}
            :
            <span className="font-weight-normal">
              {' '}
              {moment(formState.workflow.created_at).format('DD-MM-YYYY')}
            </span>
          </h6>
        </div>
      )}
      <div className="container py-5 position-relative">
        {formState.isLoading && (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center fixed-top">
            <div className="spinner-border text-primary" role="status" />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="col-sm-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
            {!formState.workflow.id && (
              <>
                <Form.Group as={Row} controlId="formPlaintextTitle">
                  <Form.Label column sm="2" className="d-flex">
                    {t('Customer Success Title')}
                    <span className="text-danger ml-2"> *</span>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      className={`form-control ${errorField('title')}`}
                      type="text"
                      {...register('title', { required: true })}
                      value={formState?.workflow?.title}
                      onChange={(event) => {
                        setFormState({
                          ...formState,
                          workflow: {
                            ...formState.workflow,
                            title: event.target.value
                          }
                        });
                      }}
                    />
                    <ErrorFieldAlert fieldName="title" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextTitle">
                  <Form.Label column sm="2" className="d-flex">
                    {t('Team Feedback Email')}
                    <span className="text-danger ml-2"> *</span>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      id="input-email"
                      className={`form-control ${errorField('email')}`}
                      type="text"
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Enter a valid email address'
                        }
                      })}
                      value={formState?.workflow?.email}
                      onChange={(event) => {
                        setFormState({
                          ...formState,
                          workflow: {
                            ...formState.workflow,
                            email: event.target.value
                          }
                        });
                      }}
                    />
                    <ErrorFieldAlert fieldName="email" />
                  </Col>
                </Form.Group>
              </>
            )}
          </div>
          <div className="stepper d-flex flex-column mt-5 ml-2">
            {formState.questions.map((question, indexQuiz) => (
              <div className="d-flex mb-1" key={question.uuid}>
                <div className="d-flex flex-column pr-4 align-items-center">
                  <div className="rounded-circle stepper-circle text-center">
                    {indexQuiz + 1}
                  </div>
                  <div className="line h-100" />
                </div>
                <div className="pt-3 pb-4 w-100">
                  <h5 className="text-dark d-flex">
                    {question.question.ask}
                    <span className="text-danger ml-2"> *</span>
                  </h5>
                  <div className="lead text-muted pb-3 my-4">
                    {question.question.answers
                      .slice(2)
                      .map((answer, index) => renderQuestionsStep(answer, indexQuiz, index))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="stepper d-flex flex-column mt-5 ml-2">
            {formState.newQuestions.map((question, indexQuiz) => (
              <div className="d-flex mb-1" key={question.uuid}>
                <div className="d-flex flex-column pr-4 align-items-center">
                  <div className="rounded-circle stepper-circle text-center">
                    {indexQuiz + 1}
                  </div>
                  <div className="line h-100" />
                </div>
                <div className="pt-3 pb-4 w-100">
                  <h5 className="text-dark d-flex">
                    {question.question.ask}
                    <span className="text-danger ml-2"> *</span>
                  </h5>
                  <div className="lead text-muted pb-3 my-4">
                    {question.question.answers
                      .slice(2)
                      .map((answer, index) => renderNewQuestionsStep(answer, indexQuiz, index))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-align-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!isFormComplete()}
            >
              {t('Customer Success Finish')}
            </button>
          </div>
        </form>
        {formState.variant && (
          <Alert className="mt-4" variant={formState.variant}>{formState.message}</Alert>
        )}
      </div>
      <Footer />
    </>
  );
}

type ErrorFieldAlertProps = {
  errors: any
  fieldName: string
}

function ErrorFieldAlert({ errors, fieldName }: ErrorFieldAlertProps) {
  const { t } = useTranslation();

  return (
    (errors && errors[fieldName]
      ? <span className="error text-danger">{t('Required field')}</span>
      : null)
  );
}
