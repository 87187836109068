exports.mappings = {
  latam: [
    'AR',
    'BO',
    'BR',
    'CL',
    'CO',
    'CR',
    'EC',
    'MX',
    'PA',
    'PE',
    'UY',
    'SV',
    'VE'
  ],
  north: ['US', 'CA'],
  europe: ['UK', 'ES', 'FR']
};

exports.rootnetCountryMappings = [
  {
    country_id: 10,
    crm_id: 1289,
    location_id: 1966,
    description: 'Argentina'
  },
  {
    country_id: 26,
    crm_id: 1667,
    location_id: 2460,
    description: 'Bolivia'
  },
  {
    country_id: 30,
    crm_id: 696,
    location_id: 51,
    description: 'Brazil'
  },
  {
    country_id: 39,
    crm_id: 1671,
    location_id: 49,
    description: 'Canada'
  },
  {
    country_id: 44,
    crm_id: 1576,
    location_id: 1668,
    description: 'Chile'
  },
  {
    country_id: 48, // Used in phone field
    crm_id: 50,
    location_id: 1289, // Used for country fields
    description: 'Colombia'
  },
  {
    country_id: 51,
    crm_id: 694,
    location_id: 696,
    description: 'Costa Rica'
  },
  {
    country_id: 53,
    crm_id: 1716,
    location_id: 0,
    description: 'Cuba'
  },
  {
    country_id: 60,
    crm_id: 1678,
    location_id: 1667,
    description: 'Dominican Republic'
  },
  {
    country_id: 62,
    crm_id: 1578,
    location_id: 2455,
    description: 'Ecuador'
  },
  {
    country_id: 64,
    crm_id: 1668,
    location_id: 2457,
    description: 'El Salvador'
  },
  {
    country_id: 73,
    crm_id: 51,
    location_id: 1190,
    description: 'France'
  },
  {
    country_id: 88,
    crm_id: 1548,
    location_id: 0,
    description: 'Guatemala'
  },
  {
    country_id: 94,
    crm_id: 1639,
    location_id: 1639,
    description: 'Honduras'
  },
  {
    country_id: 136,
    crm_id: 1190,
    location_id: 2423,
    description: 'Mexico'
  },
  {
    country_id: 164,
    crm_id: 44,
    location_id: 44,
    description: 'Panama'
  },
  {
    country_id: 166,
    crm_id: 1669,
    location_id: 2427,
    description: 'Paraguay'
  },
  {
    country_id: 167,
    crm_id: 49,
    location_id: 2428,
    description: 'Peru'
  },
  {
    country_id: 177,
    crm_id: 52,
    location_id: 2436,
    description: 'Russia'
  },
  {
    country_id: 200,
    crm_id: 698,
    location_id: 2437,
    description: 'Spain'
  },
  {
    country_id: 226,
    crm_id: 1,
    location_id: 53,
    description: 'United Kingdom'
  },
  {
    country_id: 227,
    crm_id: 53,
    location_id: 45,
    description: 'United States'
  },
  {
    country_id: 229,
    crm_id: 1188,
    location_id: 2442,
    description: 'Uruguay'
  },
  {
    country_id: 233,
    crm_id: 45,
    location_id: 2443,
    description: 'Venezuela'
  }
];
